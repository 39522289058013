import { APIError, type BaseResponse, type SubscribeRequest } from 'models/baseResponse'
import config from '../../config/config'
import type { ActionResponse } from 'models/actionPlan'

declare global {
  interface Window {
    grecaptcha: {
      ready: (cb: () => void) => void;
      execute: (siteKey: string, options: { action: string }) => Promise<string>;
    }
  }
}

const RECAPTCHA_SITE_KEY = '6LcFHsQqAAAAAE-t2V_0JpuJOmlzLLotsCvmAnyp';

const loadRecaptchaScript = async () => {
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
  document.head.appendChild(script);
  
  return new Promise<void>((resolve) => {
    script.onload = () => resolve();
  });
};

export class API {
  static prefix = '_vti_bin/InvestAPI/StrategyService.svc'

  private static async post(method: string, webServiceUrl: string = config.getWebServiceUrl()) {
    let resp
    try {
      resp = await fetch(
        `${webServiceUrl}/${API.prefix}/${method}`,
        {
          method: 'POST'
        }
      )
    } catch (error) {
      throw new APIError({ code: 'INTERNAL', message: error?.toString() ?? '', status: 0 })
    }
    if (resp.status !== 200) {
      const { error } = await resp.json()
      throw new APIError({ status: resp.status, message: error, code: 'INTERNAL' })
    }
    return await resp.json()
  }

  static async getActionData(): Promise<BaseResponse<ActionResponse>> {
    let result: BaseResponse<ActionResponse>

    try {
      result = await this.post('ActionPlan/GetItems')
    } catch (error) {
      throw new APIError({ code: 'INTERNAL', message: error?.toString() ?? '', status: 0 })
    }

    return await result
  }

  static async subscribe(request: SubscribeRequest): Promise<BaseResponse<boolean>> {
    let result: BaseResponse<boolean>

    try {

      await loadRecaptchaScript();
      const recaptchaToken = await new Promise<string>((resolve, reject) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
            .then(resolve)
            .catch(reject);
        });
      });

      result = await this.post(`Subscribe?email=${request.Email}&firstName=${request.FirstName}&lastName=${request.LastName}&recaptchaToken=${recaptchaToken}`, config.getWebServiceUrl().replace("/fdi-strategy", ""))
    } catch (error) {
      throw new APIError({ code: 'INTERNAL', message: error?.toString() ?? '', status: 0 })
    }

    return await result
  }
}
