import React, { useState } from 'react';
import Modal from '../Modal';
import { API } from '@/lib/api';
import type { SubscribeRequest } from 'models/baseResponse';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const SubscriberModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
    const [request, setRequest] = useState<SubscribeRequest>({
        Email: '',
        FirstName: '',
        LastName: ''
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showPolicy, setShowPolicy] = useState<boolean>(false);
    const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState<boolean>(false);

    const onCloseHandler = () => {
        setRequest({
            Email: '',
            FirstName: '',
            LastName: ''
        });
        setIsLoading(false);
        setIsError(null);
        setIsSuccess(false);
        setShowPolicy(false);
        onClose();
    };

    const handleChange = (field: keyof SubscribeRequest) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setRequest((prev) => ({ ...prev, [field]: e.target.value }));
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleSubmit = async () => {
        setIsLoading(true);
        setIsError(null);
        setIsSuccess(false);
        try {
            if (!request.FirstName.trim()) throw new Error('First Name is required.');
            if (!request.LastName.trim()) throw new Error('Last Name is required.');
            if (!request.Email || !emailRegex.test(request.Email)) throw new Error('Please enter a valid email address.');
            if (!agreePrivacyPolicy) throw new Error('Please agree to the Privacy Policy.');

            await API.subscribe(request);
            setIsSuccess(true);
            setRequest({ Email: '', FirstName: '', LastName: '' });
        } catch (error: any) {
            setIsError(error.message || 'An error occurred while submitting the form.');
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <Modal isOpen={isOpen} onClose={onCloseHandler} className='light-mint subscribe-modal'>
            <div className="modal-wrapper ">
                {/* Header */}
                <div className="limited-heading-set">
                    <h2 className="h3-heading">Newsletter Subscription</h2>
                    <p className="modal-description">
                        Subscribe to receive publications and newsletters on the investment climate, economic outlook, industry trends, and regulatory framework in Türkiye.
                    </p>
                </div>

                {/* Form */}
                <div className="form-wrapper">
                    <div className="a-filter-search">
                        <input
                            className="a-filter-search-text-field jetboost-list-search-input-xr5q w-input"
                            placeholder="First Name*"
                            type="text"
                            value={request.FirstName}
                            onChange={handleChange('FirstName')}
                            disabled={isLoading}
                        />
                    </div>
                    <div className="a-filter-search">
                        <input
                            className="a-filter-search-text-field jetboost-list-search-input-xr5q w-input"
                            placeholder="Last Name*"
                            type="text"
                            value={request.LastName}
                            onChange={handleChange('LastName')}
                            disabled={isLoading}
                        />
                    </div>
                    <div className="a-filter-search">
                        <input
                            className="a-filter-search-text-field jetboost-list-search-input-xr5q w-input"
                            placeholder="E-Mail Address*"
                            type="email"
                            value={request.Email}
                            onChange={handleChange('Email')}
                            disabled={isLoading}
                        />
                    </div>
                    <p className="form-note">Fields marked with " * " cannot be left blank.</p>
                </div>

                {/* Privacy Policy */}
                <div className="policy-wrapper">
                    <div className='checkbox-label'>
                        <div className={`w-checkbox-input w-checkbox-input--inputType-custom a-filter-checkbox ${agreePrivacyPolicy ? "w--redirected-checked" : ""}`}></div>
                        <input
                            type="checkbox"
                            id="privacy-policy"
                            checked={agreePrivacyPolicy}
                            onChange={(e) => setAgreePrivacyPolicy(e.target.checked)}
                        />
                        <label className="policy-container" onClick={() => { setShowPolicy(!showPolicy) }}>
                            <span className='policy-label'></span>I Agree 
                            <span className='privacy-policy-label'>Privacy Policy</span>
                        </label>
                    </div>
                    <button
                        className="button-left-icon w-button"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Loading...' : 'Subscribe Now'}
                    </button>

                </div>

                {showPolicy && (
                    <div className="policy-content">
                        <button className="policy-close" onClick={() => setShowPolicy(false)}>X</button>
                        <p className="policy-text">
                        This is to inform that the Presidency of the Republic of Türkiye Investment Office collects no information about www.fdistrategy.gov.tr visitors that is not supplied voluntarily through web forms or e-mails. The e-mail addresses and personal information collected from subscriptions to the Invest in Türkiye newsletters are not sold, exchanged, or disclosed to any third parties. These e-mail addresses are only used by the Investment Office to send out newsletters, which may include but are not limited to updates and announcements. The Investment Office guarantees that newsletter subscribers will not receive any mailings they do not request. The e-mail addresses collected at www.fdistrategy.gov.tr are stored in the Investment Office's servers in line with the principle of confidentiality. Newsletter subscribers may choose to remove themselves from the Investment Office's distribution lists at any time by simply clicking e-mail preferences at the bottom of any newsletter mailing.
                        </p>
                    </div>
                )}

                {/* Submit */}


                {/* Messages */}
                {isSuccess && <div className="success-message">Your request has been successfully submitted.</div>}
                {isError && <div className="error-message">{isError}</div>}


            </div>
        </Modal>
    );
};

export default SubscriberModal;